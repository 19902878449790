@import '../../../../styles/core/core';

.icon {
  // base icon
  transition: fill 0.3s ease;
  fill: currentColor;

  // dashboard tabs icons
  .color--bg {
    fill: $grey-light;
    transition: fill 0.3s ease;
    .active & {
      fill: $grey-lightest;
    }
  }
  .color--icon,
  .color--icon > * {
    fill: $grey-medium;
    transition: fill 0.3s ease;
    .active & {
      fill: $blue-dark;
    }
  }

  // icons in tables
  &--table {
    margin-top: 4px;
    fill: $grey-light;
    transition: fill .3s ease;
    & + & {
      margin-left: 1rem;
    }
    &:hover {
      fill: $grey-dark;
      cursor: pointer;
    }
  }

  // color variants for icons
  &--neutral {
    fill: $grey-light;
  }
  &--success {
    fill: $green;
  }
  &--warning {
    fill: $orange;
  }
  &--danger {
    fill: $red;
  }

  // hover variants for icons
  &--hover-danger {
    &:hover {
      fill: $red;
      cursor: pointer;
    }
  }

  // info icon
  &--info {
    &:hover {
      cursor: help;
      fill: $blue-dark;
    }
  }
}
