@import '../../../../../styles/core/core';

// The sidebar component is fixed to the left.
// You can collapse the view to see just the icons by clicking the 'Collapse' toggle.
// Width is controlled by variables (which also control the offset of the main container).

.sidebar {
  transform: translateX(-$sidebar-width + $padding);
  position: fixed;
  left: 0;
  bottom: 0;
  top: $sidebar-top;
  width: $sidebar-width;
  padding: 1.5*$padding $padding $padding;
  transition: width 0.3s ease, transform 0.3s ease;
  z-index: $z-middle; // for collapsed state tooltips
  &.is-collapsed {
    width: $sidebar-collapsed-width;
    // display: flex;
    // justify-content: center;
  }
  @include media-up(sm) {
    transform: none;
  }
  &.is-opened {
    transform: translateX(0);
    top: $sidebar-top;
  }
}

.sidebar__section-header {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: $grey-medium;
  margin: 2rem 0 1rem;
  .is-collapsed & {
    font-size: 0;
    border-bottom: 1px solid $grey-light;
    margin-bottom: 2.5rem;
  }
}

.sidebar-divider {
  height: 1px;
  background-color: $grey-light;
  margin-bottom: 15px;
  .is-collapsed & {
    margin-bottom: 2.5rem;
  }
}

.sidebar-nav__item {
  font-size: 1.4rem;
  margin-bottom: 15px;
  color: $grey-medium;
  transition: color 0.3s ease, margin 0.3s ease;
  position: relative;
  &:hover {
    color: inherit;
    cursor: pointer;
  }
  &--active {
    color: inherit;
  }
  .is-collapsed & {
    margin-bottom: 25px;
  }
}

// target just the collapse icon
.is-collapsed .sidebar-nav__item--collapse .icon {
  transform: rotate(180deg);
}

// icons
.icon--inactive {
  opacity: 1;
  transition: opacity 0.3s ease;
  .sidebar-nav__item--active & {
    opacity: 0;
  }
}
.icon--active {
  opacity: 0;
  transition: opacity 0.3s ease;
  .sidebar-nav__item--active & {
    opacity: 1;
  }
}

.color--light {
  fill: $grey-light;
}

.color--medium {
  fill: $green;
  .icon--inactive & {
    fill: $grey-medium;
    transition: fill 0.3s ease;
    .sidebar-nav__item:hover & {
      fill: $grey-darkest;
    }
  }
}

.color--dark {
  fill: darken($green, 30%);
}

// link
.sidebar-nav__link {
  position: absolute;
  top: 1px;
  left: 33px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  .sidebar-nav__item--active & {
    font-weight: 500;
  }
  .is-collapsed & {
    transform: scale(0);
    opacity: 0;
  }
}

// tooltip
.sidebar-nav__tooltip {
  font-size: 1.4rem;
  text-align: center;
  background: $grey-darkest;
  color: white;
  padding: 6px 8px;
  width: 9rem;
  position: absolute;
  left: 35px;
  top: -5px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none; // needed to prevent the tooltip being triggered when hovered over in collapsed mode
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: $grey-darkest;
    position: absolute;
    left: -4px;
    top: calc(50% - 4px);
    border-radius: 2px;
    transform: rotate(45deg);
  }
  .is-collapsed .sidebar-nav__item:hover & {
    opacity: 1;
    transform: scaleX(1) translateX(5px);
  }
}
