@import '../../styles/core/core';

.lead-product-use {
  display: flex;
  flex-direction: column;
  @include media-up(lg) {
    flex-direction: row;
  }
}

.lead-product-use__chart {
  min-height: 45rem;
  padding: 2rem;
  flex: 0 0 100%;
  display: flex;
  &:first-of-type {
    border-bottom: 1px solid $grey-light;
  }
  &:last-of-type {
    border-top: 1px solid $grey-light;
  }
  & > .pie-chart,
  & > .bar-chart {
    position: relative;
    width: 100%;
  }
  & > .pie-chart {
    .sb-chart {
      position: absolute;
      margin: auto;
      top: calc(50% - 18rem);
      left: 0;
      right: 0;
    }
  }
  // this is required for highcharts weird resizing issues
  // https://stackoverflow.com/questions/18445784/highcharts-charts-dont-resize-properly-on-window-resize
  & > .bar-chart {
    & > .sb-chart {
      position: absolute;
      margin: auto;
      top: calc(50% - 18rem);
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  @include media-up(lg) {
    flex: 0 0 33.33%;
    &:first-of-type {
      border-bottom: 0;
      border-right: 1px solid $grey-light;
    }
    &:last-of-type {
      border-top: 0;
      border-left: 1px solid $grey-light;
    }
  }
}

.lead-info {
  @include media-up(md) {
    display: flex;
  }
}

.lead-img,
.lead-contact {
  padding: 20px;
}

.lead-img {
  display: flex;
  flex-direction: column;
  @include media-up(md) {
    flex: 0 0 20%;
    border-right: 1px solid $grey-light;
  }
}

.lead-img__img {
  display: block;
  max-width: 50%;
  min-width: 50px;
  margin: auto;
  border-radius: 100%;
}

.lead-contact {
  border-bottom: 1px solid $grey-light;
  border-top: 1px solid $grey-light;
  @include media-up(md) {
    flex: 0 0 46.67%;
    border: 0;
  }
}

.lead-contact__details {
  display: table;
}

.contact-item {
  display: table-row;
  margin-bottom: 1rem;
  &:last-of-type {
    margin: 0;
    & > .contact-prop,
    & > .contact-val {
      padding-bottom: 0;
    }
  }
}

.contact-prop {
  font-weight: 500;
  display: table-cell;
  padding: 0 0 1rem 0;
}

.contact-val {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 0 0 1rem 2rem;
  display: inline-block;
  vertical-align: top;
}

.lead-activity {
  display: flex;
  @include media-up(md) {
    flex: 0 0 33.33%;
    border-left: 1px solid $grey-light;
  }
}

.lead-activity__wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.lead-activity__group {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  &:first-of-type {
    border-bottom: 1px solid $grey-light;
  }
}

.lead__info {
  padding: $padding $padding 1px;
  background: $grey-lightest;
  border: 1px solid $grey-light;
  border-radius: $radius;
  margin-bottom: 20px;
}
