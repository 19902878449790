@import '../../../styles/core/core';

// .sb-chart is the className assigned to bar/line/column charts and affects styling scoped to these chart types, not maps as well

// base svg class
.highcharts-root {
  font-family: inherit !important; // need to override inline styles
}

// line graph
.highcharts-graph {
  stroke: $grey-medium;
}

.sb-chart {
  padding-bottom: 0.4rem;
  // plot points or bars/columns
  .highcharts-point {
    fill: $green;
    stroke: $white;
    stroke-width: 2;
  }
  .highcharts-color-1 {
    fill: $grey-medium;
    .highcharts-point {
      fill: $grey-medium;
    }
  }
  .highcharts-color-2 {
    fill: $blue-dark;
    .highcharts-point {
      fill: $blue-dark;
    }
  }
  .highcharts-color-3 {
    fill: $grey-dark;
    .highcharts-point {
      fill: $grey-dark;
    }
  }
  // x-axis ticks
  .highcharts-tick {
    stroke-width: 0;
  }
}

.activity-type-chart,
.channel-activity-chart {
  .highcharts-legend-box {
    stroke: $white;
  }
}

// tooltip box
.highcharts-tooltip-box {
  fill: $grey-dark;
  stroke: none;
}

// tooltip text
.highcharts-tooltip text {
  fill: $white !important;
  color: $white !important;
}

// legend
.highcharts-legend-box {
  fill: $white;
  stroke: $grey-light;
  rx: 3;
  ry: 3;
}

// dot style in the tooltip
.sb-chart {
  .sb-highcharts-tooltip-header {
    font-size: 14px;
    font-weight: 500;
  }
  // dot style in the tooltip
  .sb-highcharts-tooltip-point {
    fill: $green;
  }
}

// map
.insights-map {
  padding: $padding;
  @include media-up(md) {
    width: 70%;
  }
}

.sb-map {
  .highcharts-title {
    display: none;
  }
  // .highcharts-point {
  //   fill: lighten($grey-medium, 10%);
  //   stroke: $grey-darkest;
  //   fill: $green;
  //   stroke: darken($green, 10%);
  //   &:not(.highcharts-null-point):hover {
  //     fill: $green;
  //     fill: lighten($green, 10%);
  //     stroke: darken($green, 20%);
  //     stroke-width: 2;
  //   }
  // }
  // .highcharts-null-point {
  //   fill: lighten($grey-medium, 38%);
  //   stroke: lighten($grey-medium, 30%);
  // }
}

// InsightsContentSnapshotContentSidebar
.insights-snapshot {
  @include media-up(md) {
    display: flex;
  }
}

.insights-stats {
  display: flex;
  flex-direction: column;
  @include media-up(md) {
    flex: 0 0 30%;
    border-right: 1px solid $grey-light;
  }
}

.stats-wrapper {
  flex: 1;
}

.insights-stats-group {
  text-align: center;
  border-bottom: 1px solid $grey-light;
  flex: 1;
  display: flex;
  align-items: center;
  &:last-of-type {
    border-bottom: 0;
  }
}

.main-stat {
  margin-bottom: 20px;
}

.compare-stat {
  display: flex;
  justify-content: space-around;
}

.stat-title {
  color: lighten($grey-medium, 20%);
  margin-bottom: 0;
  font-family: $font-main;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
}

.stat-num {
  font-weight: 500;
  font-size: 2.2rem;
  &--main {
    font-size: 3rem;
  }
}
