@import '../../../../../styles/core/core';

.panel-button {
  padding: $padding;
  border-radius: $radius;
  border: 2px solid $grey-light;
  text-align: center;
  min-width: 32.33%;
  margin: 0.5rem 0.5%;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
  width: 49%;
  &:hover {
    background-color: $grey-lightest;
    cursor: pointer;
    border-color: $grey-medium;
  }
}

.panel-button__helper-text {
  font-size: 1.2rem;
  margin: 0;
}
