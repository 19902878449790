@import '../../../styles/core/core';
.projects-card {
  .analytic-card {
    padding: 1.5rem 3rem 3rem;
    color: $grey-darkest;
    width: 100%;
    margin: 0 0 2rem 0;
    height: 34rem;
    display: flex;
    flex-direction: column;

    .card-title {
      margin-bottom: 4rem;
      font-size: 1.8rem;
      font-weight: 500;
    }

    .card-content {
      margin: 0 -2rem;
      display: inline;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .aggregate-value {
        color: $blue-dark;
        font-size: 4rem;
        line-height: 1;
        font-weight: 800;
        @include media-up(md) {
          font-size: 5rem;          
        }
        @include media-up(lg) {
          font-size: 6.5rem;          
        }
        @include media-up(xl) {
          font-size: 7.5rem;          
        }
      }
      .trend {
        svg {
          height: 15px;
          font-weight: 800;
        }

        .up {
          color: $green;
        }

        .down {
          color: $red;
        }

        .up,
        .down {
          font-size: 2rem;
          font-weight: 800;
        }
      }
    }

    .card-footer {
      margin-top: 40px;
    }

    .card-date-range {
      color: $grey-medium;
      margin-top: 0.5rem;
      font-size: 1.4rem;
    }

    .prefix,
    .suffix,
    .ext {
      font-size: 2rem;
      @include media-up(md) {
        font-size: 2.4rem;          
      }
      @include media-up(lg) {
        font-size: 3rem;          
      }
      @include media-up(xl) {
        font-size: 4rem;          
      }
    }
    .ext {
      margin-left: 1rem;
    }
  }
}
