@import '../../../../styles/core/core';

.instructions {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 5px;
    background: $grey-medium;
    left: 0;
    bottom: 0;
    top: 0;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  .button--link {
    font-size: 3rem;
  }
  .icon {
    position: relative;
    top: 2px;
    margin: 0 0.5rem;
  }
}
.instructions__body {
  padding: 2rem 2rem 1rem;
}

.shared-parameters {
  display: flex;
}

.shared-parameter__file {
  padding: 2rem;
  flex: 0 0 75%;

  .button--link {
    font-size: 3rem;
    margin-right: -2rem;
  }
}

.shared-parameter__meta {
  flex: 0 0 25%;
  padding: $padding;
  border-left: 1px solid $grey-light;
}

.upload-info{
  border: 1px solid $green;
  background-color: $white;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: -20px;
  position: relative;
  padding-left: 36px;
  &.red{
    color: $red;
    border-color: $red;
    .upload-info__icon{
      background: $red;
    }
    .button--default:hover{
      color: $red;
    }
  }
  &.green{
    border-color: $green;
    .upload-info__icon{
      background: $green;
    }
    .button--default:hover{
      color: $green;
    }
  }
  &.blue{
    color: $blue-dark;
    border-color: $blue-dark;
    .upload-info__icon{
      background: $blue-dark;
    }
    .button--default:hover{
      color: $blue-dark;
    }
  }
  .upload-info__icon{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $green;
    color: $white;
    width: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
  }
  .upload-info__text {
    margin: 0;
    flex-grow: 2;
    margin-left: 10px;
    font-size: 14px;
    padding: 5px 0;
  }
  .button--default{
    border: none;
    font-size: 24px;
  }
}
