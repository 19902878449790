@import '../../../../../styles/core/core';
// @import '../../../../../styles/global/input.scss';

.header {
  padding: $padding;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  background: $white;
  box-shadow: 0 1px 3px lighten($grey-medium, 30%);
  z-index: $z-middle;
}

.branding {
  display: flex;
  align-items: center;
}

.branding__logo {
  width: 2.5rem;
  height: 2.5rem;
  background: url('../../../../../styles/concora-favicon.png') no-repeat;
  background-size: 100%;
}

.branding__name {
  font-size: 2rem;
  font-weight: 500;
  margin-left: 1rem;
  position: relative;
  top: -1px;
}

// mobile menu
// modified from https://jonsuh.com/hamburgers/
.mobile-menu {
  display: inline-block;
  @include media-up(sm) {
    display: none;
  }
  &:focus {
    outline: 0;
  }
}

.mobile-menu {
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  &.is-active {
    .mobile-menu__inner {
      transform: rotate(225deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.mobile-menu__box {
  width: 26px;
  height: 3px * 3 + 5px * 2;
  display: inline-block;
  position: relative;
}

.mobile-menu__inner {
  display: block;
  top: 50%;
  margin-top: 3px / -2;
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  &::before,
  &::after {
    width: 26px;
    height: 3px;
    background-color: $grey-darkest;
    border-radius: $radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: (4px + 3px) * -1;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  }

  &::after {
    bottom: (4px + 3px) * -1;
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

// spacing for header items by group
.header__left,
.header__right {
  display: flex;
  align-items: center;
}

// header search
.header-search {
  display: none;
  @include media-up(sm) {
    display: flex;
    position: relative;
    width: 30rem;
    margin-left: 3rem;
    & > input {
      width: 100%;
      line-height: 1.6;
    }
  }
}

.user-menu {
  display: inline-block;
  position: relative;
}

.user-menu__icon {
  color: $white;
  background: $grey-medium;
  font-weight: 500;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background 0.3s ease;
  &:hover {
    background: $grey-dark;
  }
}

.user-menu__tooltip {
  font-size: 1.4rem;
  background: $grey-darkest;
  color: $white;
  padding: 6px 8px 5px;
  position: absolute;
  width: 7rem;
  text-align: center;
  right: -2px;
  top: 30px;
  border-radius: $radius;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: $grey-darkest;
    position: absolute;
    right: 11px;
    top: -4px;
    border-radius: 2px;
    transform: rotate(45deg);
  }
  .user-menu:hover & {
    opacity: 1;
    transform: translateY(5px);
  }
}
