@import '../../../../styles/core/core';

// dashboard tabs styling
.dashboard-tab {
  display: flex;
  align-items: center;
}

.tab-stats {
  margin: 0 15px;
}

.tab-count {
  font-size: 2.6rem;
  line-height: 1;
  font-weight: 500;
  margin-top: 10px;
}

.tab-metric {
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}
