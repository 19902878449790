@import '../../../../styles/core/core';

.card {
  background: $white;
  border-radius: $radius;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0 40px;
  &--padded {
    padding: $padding;
  }
}

.card__header {
  padding: $padding;
  @include media-up(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
  }
  &--bordered {
    border-bottom: 1px solid $grey-light;
  }
  &--slim {
    padding: 0 0 $padding;
    height: auto;
  }
}

.card__title {
  font-family: $font-main;
  font-size: 1.6rem;
}

.card__subtitle {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
  color: $grey-medium;
}

.card__body {
  padding: $padding;
  @include media-up(md) {
  }
  &--half {
    @include media-up(md) {
      flex: 0 0 50%;
    }
  }
}

// card within tab content area
.tab-content .card {
  margin: 0;
  box-shadow: none;
}

// radios styled as buttons
.controls {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  @include media-up(md) {
    margin-top: 0;
    justify-content: flex-end;
  }
}

.control-buttons {
  display: flex;
  // padding-left: 10px;
}

.radio-btn {
  opacity: 0;
  position: absolute;
  & + .radio-btn-label {
    transition: background 0.3s ease, color 0.3s ease;
  }
  &:checked {
    & + .radio-btn-label {
      background: $grey-medium;
      color: $white;
    }
  }
}
