@import '../../../../styles/core/core';

// control fade transitions
.tab-pane {
  display: none;
  &.active {
    display: block;
  }
  @extend %fade;
}

// regular tabs styling
.nav-tabs {
  list-style: none;
  display: flex;
  border-bottom: 1px solid $grey-light;
  padding: 0 15px;
  margin: 0;
  & > li {
    min-width: 120px;
    height: 100%;
    padding: 15px;
    margin: 0 10px;
    font-size: 1.6rem;
    &.active {
      font-weight: 500;
      box-shadow: inset 0px -2px 0 0px $blue-dark, 0px 1px $blue-dark;
      transition: all 0.3s ease-in-out;
      & > a {
        color: $grey-darkest;
      }
    }
    & > a {
      text-decoration: none;
      color: $grey-medium;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: none;
      }
    }
  }
}

// stacked nav tabs styling
.subsection {
  display: flex;
}

.subsection-nav {
  padding: $padding 15px;
  width: 17.5rem;
  float: left;
  border-right: 1px solid $grey-light;
}

.subsection-content {
  padding: $padding;
  width: calc(100% - 17.5rem);
  float: right;
}

.subsection-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  & > li {
    text-align: right;
    margin-bottom: 7px;
    font-size: 1.4rem;
    transition: color 0.3s ease;
    &:after {
      content: '';
      position: absolute;
      width: 3px;
      height: 1.7rem;
      background: $blue-dark;
      right: -16px;
      top: 1px;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }
    &.active {
      font-weight: 500;
      color: $blue-dark;
      position: relative;
      &:after {
        transform: scaleX(1);
        transform-origin: right;
      }
    }
    &.disabled {
      color: $grey-light;
    }
    & > a {
      text-decoration: none;
      color: inherit;
      &:focus {
        outline: 0;
      }
    }
  }
}
