@import 'core/core';

/*

this file sets base styles for our app.

*/

html {
  box-sizing: border-box;
  letter-spacing: 0;
  // font-size: 10px;
  font-size: 62.5%;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: $font-main;
  color: $grey-darkest;
  line-height: 1.4;
  &.bgDropzone {
    .header {
      z-index: 0;
    }
    .sidebar {
      z-index: 0;
    }
  }
}

body {
  font-size: 1.4rem;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.mc-body {
  background: $grey-lightest;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: $font-main !important;
  font-size: 1.4rem;
  color: $grey-medium;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: $font-main !important;
  font-size: 1.4rem;
  color: $grey-medium;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: $font-main !important;
  font-size: 1.4rem;
  color: $grey-medium;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: $font-main !important;
  font-size: 1.4rem;
  color: $grey-medium;
}

h1,
h2 {
  font-family: $font-heading;
  font-weight: 500;
  margin: 0;
}

h3,
h4 {
  font-weight: 500;
  margin: 0;
}

a {
  color: $blue-dark;
  text-decoration: none;
}

p {
  margin-top: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

.ReactVirtualized__Table__row.hasTooltip,
.ReactVirtualized__Table__headerRow.hasTooltip {
  overflow: visible !important;
}

.ReactVirtualized__Table__rowColumn {
  white-space: nowrap;
  a {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }
}
// for tooltip
// .ReactVirtualized__Table__Grid,
// .ReactVirtualized__Grid__innerScrollContainer {
//   overflow: visible !important;
// }
