@import '../../../styles/core/core';

.revit-source {
  display: flex;
  border-bottom: 1px solid $grey-light;
  margin: -2rem -2rem 2rem;

  select.form-control {
    flex: 0 0 78%;
  }

  .file-edit {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    flex: 0 0 77%;
    padding: 2rem;

    .details {
      flex: 0 0 30%;
    }

    .file-selection {
      flex: 0 0 67%;
    }
  }

  &__img {
    max-width: 100%;
  }

  .product-details__input {
    flex: 0 0 100%;
  }
}

.file-selection--revit {
  border-bottom: 1px solid lighten($grey-light, 5%);
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}

.revit-source__title {
  flex: 0 0 100%;
}

.status-message {
  color: $grey-medium;
  font-size: 1.3rem;
}

.revit-source__meta {
  flex: 0 0 23%;
  border-left: 1px solid $grey-light;
  padding: 2rem;
}
