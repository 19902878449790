@import '../../styles/core/core';

.product-grid {
  margin: 2rem 0 0 -1rem;
}

.product-grid__item {
  padding: 0 1rem 2rem;
}

.product-grid__item-wrapper {
  background: $white;
  border-radius: $radius;
  height: 100%;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05), 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: hidden;

  &:hover {
    .product-grid__item-actions {
      transform: scale(1);
      background: rgba(255, 255, 255, 0.8);
      height: 16%;
    }
    .product-grid__item-img > img {
      filter: blur(5px);
    }
    .product-grid__item-main {
      transform: translateY(calc(29% + 3px));
      height: 55%;
      & > h4 {
        max-height: 9rem;
        -webkit-line-clamp: 4;
      }
    }
    .product-grid__item-meta {
      transform: translateY(55%);
      height: 29%;
      background-color: $white;
      .product-grid__item-meta-prop {
        margin-bottom: 0.5rem;
      }
    }
  }

  & > .checkbox {
    position: absolute;
    z-index: $z-top;
    top: 1.5rem;
    left: 2.5rem;
    right: 0;
  }

  .product-grid__item-actions {
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 20%;
    z-index: $z-middle;
    border-radius: $radius $radius 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
  }

  .icon--grid {
    fill: $grey-medium;
    margin: 0 0.5rem;
  }

  .product-grid__item-img {
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 70%;
    text-align: center;
    border-radius: $radius $radius 0 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .product-grid__item-main {
    padding: 1rem;
    transform: translateY(240%);
    transition: transform 0.2s ease-in-out;
    height: calc(30% - 2px);
    border-top: 1px solid $grey-light;
    background: $white;
    & > p {
      margin: 0;
    }
    & > h4 {
      // https://codepen.io/blazebarsamian/pen/ZJJYOy css only cross-browser multi-line text truncation
      background: $white;
      display: block;
      /* Fallback for non-webkit */
      display: -webkit-box;
      max-height: 4.5rem;
      /* Fallback for non-webkit */
      line-height: 1.4;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @-moz-document url-prefix() {
        overflow: hidden;
        position: relative;
        &:before {
          background: $white;
          bottom: 0;
          position: absolute;
          right: 0;
          float: right;
          content: '\2026';
          margin-left: -3rem;
          width: 3rem;
        }
        &:after {
          content: '';
          background: $white;
          position: absolute;
          height: 50px;
          width: 100%;
          z-index: 1;
        }
      }
    }
  }

  .product-grid__item-meta {
    padding: 1rem;
    transform: translateY(400%);
    transition: transform 0.2s ease-in-out;
    border-top: 1px solid $grey-light;
  }

  .product-grid__item-meta-prop {
    color: $grey-medium;
    font-size: 1.4rem;
    //margin-bottom: 0.5rem;
  }
}
