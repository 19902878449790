@import '../../../assets/buttons-core';

.button {
  // core styles imported
  @extend %button;
  &--create {
    background: $teal;
    border-color: $teal;
    color: $white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background: darken($teal, 3%);
      border-color: darken($teal, 3%);
    }
    svg * {
      fill: $white;
    }
  }
  &--blue {
    background: $blue-dark;
    border-color: $blue-dark;
    color: $white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background: darken($blue-dark, 3%);
      border-color: darken($blue-dark, 3%);
    }
    svg * {
      fill: $white;
    }
  }
  &--grey {
    background: $grey-medium;
    border-color: $grey-medium;
    color: $white;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    &:hover {
      background: darken($grey-medium, 3%);
      border-color: darken($grey-medium, 3%);
    }
    svg * {
      fill: $white;
    }
  }
  &--default,
  &--select {
    background-color: $white;
    color: $grey-medium;
    border-color: $grey-medium;
    svg:not(.checkbox__icon) * {
      fill: $grey-medium;
    }
    &:hover {
      color: $grey-dark;
      border-color: $grey-dark;
      svg:not(.checkbox__icon) * {
        fill: $grey-dark;
      }
    }
  }
  &--green {
    @include button-variant($green);
  }
  &--red {
    @include button-variant($red);
  }
  &--orange {
    @include button-variant($orange);
  }

  &--red-fill {
    color: $white;
    background-color: $red;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-color: darken($red, 3%);
    &:hover {
      color: $red;
      background-color: $white;
    }
    svg * {
      fill: $white;
    }
  }
  &--link,
  &--link-green,
  &--link-red {
    border: 0;
    background-color: transparent;
    padding: 0;
  }
  &--link {
    color: $grey-medium;
    padding-left: 1rem;
    &:hover {
      color: $blue-dark;
    }
  }
  &--link-green {
    color: $green;
    margin: 0;
  }
  &--link-red {
    color: $red;
    margin: 0;
  }
  &--select {
    height: 34px;
    padding: 0 8px;
  }
  // &--menu, &--menu-active {
  //   display: inline;
  //   text-align: left;
  //   border: 0;
  //   background-color: transparent;
  //   padding: 8px 0 8px 22px;
  //   margin: 4px 0;
  //   border-radius: 0;
  //   color: $grey-dark;
  //   &:hover {
  //     color: $blue-dark;
  //   }
  //   &-active, &:active {
  //     color: $blue-dark;
  //     border-left: 4px solid $blue-dark;
  //     padding-left: 18px;
  //   }
  // }
  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
    &:hover {
      border-color: $grey-medium;
      color: $grey-medium;
    }
  }

  &.is-active {
    border-color: $blue;
    border-bottom-color: $grey-light;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 0px 6px transparentize($grey-darkest, 0.7);
  }
  & + & {
    margin-left: 0.5rem;
  }
}

.button__icon {
  margin: 0 8px 0 0;
  &--after {
    margin: 0 0 0 8px;
  }
  & > * {
    fill: currentColor;
  }
}
