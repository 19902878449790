@import '../../../styles/core/core'; 

.file-drop { 
 border: 1px solid $grey-light; 
 border-radius: $radius; 
 background: $grey-lightest; 
 padding: $padding; 
 width: 20rem; 
 height: 20rem; 
 margin: 20px 0; 
 font-size: 1.4rem; 
 display: flex; 
 align-items: center; 
 justify-content: center; 
 &--active { 
   border-color: $blue-dark; 
   box-shadow: inset 0 0 3px rgba(0,0,0,0.2); 
 } 
} 

.flex-container { 
 display: flex; 
 flex-wrap: wrap; 
 margin: 0 -0.5%; 
} 