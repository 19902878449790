@import '../../../styles/core/core';

// the main section contains all non-sidebar, non-header content
// the width and positioning of the main div is dependent on whether mobile menu is open and on desktop, whether the sidebar menu is collapse

.main {
  padding: 1.5*$padding $padding 0;
  transform: translate3d(0, $sidebar-top, 0);
  transition: transform 0.3s ease;
  min-height: calc(100vh - 12rem);
  height: auto;
  margin-bottom: 3rem;
  @include media-up(sm) {
    width: calc(100% - #{$sidebar-width - $padding});
    transform: translate3d($sidebar-width - $padding, $sidebar-top, 0);
    // is the main section expanded (i.e. is sidebar collapsed)?
    &.is-expanded {
      width: calc(100% - #{$sidebar-collapsed-width - $padding});
      transform: translate3d($sidebar-collapsed-width - $padding, $sidebar-top, 0);
    }
  }
  // is the main section slid off-screen (i.e. mobile menu is opened)?
  &.menu-open {
    transform: translate3d($sidebar-width - $padding, $sidebar-top, 0);
  }
}
