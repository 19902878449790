@import '../../../../styles/core/core';

// radios styled as buttons
.controls {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  @include media-up(md) {
    margin-top: 0;
    justify-content: flex-end;
  }
}

.control-buttons {
  display: flex;
  // padding-left: 10px;
  & > :first-child label {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
  }
  & > :last-child label {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
  }
}



.radio-btn {
  opacity: 0;
  position: absolute;
  & + .radio-btn-label {
    transition: background 0.3s ease, color 0.3s ease;
  }
  &:checked {
    & + .radio-btn-label {
      background: $grey-medium;
      color: $white;
    }
  }
}

.radio-btn-label {
  color: $grey-medium;
  background: $white;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02rem;
  padding: 7px 8px;
  border: 1px solid $grey-medium;
  float: left;
  cursor: pointer;
  margin-left: -1px;
  &.button-label {
    height: 34px;
    border-width: 2px;
    padding: 3px;
  }
  &:hover {
    background: $grey-lightest;
  }
}
