@import '../../styles/core/core';

.auth {
  padding-top: 5rem;
  .branding {
    justify-content: center;
    margin-bottom: 2rem;
  }
}

.auth__container {
  width: 30rem;
  margin: 0 auto;
  padding: $padding;
  border-radius: 5px;
  border: 1px solid $grey-light;
  background: $white;
  .input--text {
    width: 100%;
    margin: 0.25rem 0 0;
  }
  .button {
    width: 100%;
    margin: 2rem 0 0;
  }
}

.auth__title {
  text-align: center;
  margin: 0 -2rem 2rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $grey-light;
  position: relative;
}

.login-back {
  position: absolute;
  top: 3px;
  left: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  padding-right: 2px;
  border: 2px solid $grey-light;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: $grey-lightest;
  cursor: pointer;
}

.message {
  color: $white;
  margin: -2rem -2rem 2rem;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.3px;
  &--danger {
    background: $red;
  }
  &--success {
    background: $green;
  }
}

.reset-pswd {
  text-align: center;
  font-size: 1.3rem;
  margin: 1rem 0 0;
}

.auth__footer {
  font-size: 1.3rem;
  color: $grey-medium;
  background: $grey-lightest;
  margin: 2rem -2rem -2rem;
  padding: 1.5rem;
  border-radius: 0 0 5px 5px;
}

.error {
  font-size: 1.3rem;
  display: none;
  margin: 0.25rem 0 0;
}
