@import '../../../assets/buttons-core';

// local variables
$dropdown-caret: url('data:image/svg+xml,<svg viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg"><path fill="#{$grey-medium}" fill-rule="evenodd" d="M 6 4.56204L 10.6668 0.082741C 10.782 -0.0297774 10.9722 -0.0270984 11.0901 0.088099L 11.9099 0.889122C 12.0278 1.00432 12.0304 1.19185 11.9152 1.30437L 6.217 6.7749C 6.15806 6.83384 6.07769 6.86063 6 6.85527C 5.91963 6.85795 5.84194 6.83116 5.783 6.7749L 0.0847496 1.30437C -0.0304477 1.19185 -0.0277687 1.00432 0.0901076 0.889122L 0.909884 0.088099C 1.02776 -0.0270984 1.21797 -0.0297774 1.33317 0.082741L 6 4.56204Z"/></svg>');
$dropdown-caret--green: url('data:image/svg+xml,<svg viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg"><path fill="#{$green}" fill-rule="evenodd" d="M 6 4.56204L 10.6668 0.082741C 10.782 -0.0297774 10.9722 -0.0270984 11.0901 0.088099L 11.9099 0.889122C 12.0278 1.00432 12.0304 1.19185 11.9152 1.30437L 6.217 6.7749C 6.15806 6.83384 6.07769 6.86063 6 6.85527C 5.91963 6.85795 5.84194 6.83116 5.783 6.7749L 0.0847496 1.30437C -0.0304477 1.19185 -0.0277687 1.00432 0.0901076 0.889122L 0.909884 0.088099C 1.02776 -0.0270984 1.21797 -0.0297774 1.33317 0.082741L 6 4.56204Z"/></svg>');
$dropdown-caret--hover: url('data:image/svg+xml,<svg viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg"><path fill="#{$white}" fill-rule="evenodd" d="M 6 4.56204L 10.6668 0.082741C 10.782 -0.0297774 10.9722 -0.0270984 11.0901 0.088099L 11.9099 0.889122C 12.0278 1.00432 12.0304 1.19185 11.9152 1.30437L 6.217 6.7749C 6.15806 6.83384 6.07769 6.86063 6 6.85527C 5.91963 6.85795 5.84194 6.83116 5.783 6.7749L 0.0847496 1.30437C -0.0304477 1.19185 -0.0277687 1.00432 0.0901076 0.889122L 0.909884 0.088099C 1.02776 -0.0270984 1.21797 -0.0297774 1.33317 0.082741L 6 4.56204Z"/></svg>');

.button.button--dropdown {
  padding: 8px 30px 8px 12px;
  background-image: $dropdown-caret;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: right 10px center;
  &.dropdown--icon {
    background-image: none;
    padding: 0 12px;
    height: 34px;
  }
  &.button--green {
    background-image: $dropdown-caret--green;
    &:hover {
      background-image: $dropdown-caret--hover;
    }
  }
}

.dropdown__title {
  display: flex;
  align-items: center;
}

.dropdown__title svg {
  margin-left: 8px;
}

.dropdown {
  display: inline-block;
  position: relative;
  & > .dropdown-menu {
    display: none;
    border: 2px solid $grey-medium;
    position: absolute;
    min-width: 17rem;
    right: 2px;
    top: 100%;
    list-style: none;
    padding: 0;
    margin: 4px 0;
  }
  &.open > .dropdown-menu {
    display: block;
    text-align: left;
    z-index: 300;
    border-radius: $radius;
  }
  & + .button,
  .button + & {
    margin-left: 4px;
  }
}

.dropdown__item {
  font-weight: 500;
  border-bottom: 2px solid $grey-medium;
  background: $white;
  cursor: pointer;
  &:hover {
    background: $grey-lightest;
  }
  &:first-of-type {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }
  &:last-of-type {
    border: 0;
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

a[role='menuitem'] {
  display: block;
  padding: 7px 12px;
  text-decoration: none;
  color: $grey-medium;
  transition: color 0.3s ease;
}

.dropdown__item.dropdown__item--sort a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 36px;
}

