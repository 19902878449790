@import '../../../styles/core/core';

%attribute-modal-table-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: $grey-dark;
  text-transform: uppercase;
  padding: 15px 20px;
}

.attribute__list {
  display: flex;
  .modal-body & {
    margin: -2rem;
  }
}

.bottom-border {
  border-bottom: 1px solid $grey-light;
}

.attribute-library {
  width: 280px;
  background-color: lighten($grey-lightest, 3%);
  border-right: 1px solid $grey-light;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  &__title {
    @extend %attribute-modal-table-title;
  }
  &__content {
    flex: 1;
  }
  &__controls {
    padding: 0 2rem 1rem;
  }
};

.attribute__item {
  padding: 1rem 2rem;
  list-style: none;
  &:hover {
    background: $grey-lightest;
    cursor: pointer;
  }
  &--selected,
  &--selected:hover {
    background: lighten($grey-light, 8%);
  }
}

.AttributeContent {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  position: relative;
}

.AttributeContentForm {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  padding: 20px 20px 70px;
  overflow-y: auto;
}


.form__attribute-unit {
  margin-bottom: 2.5rem;
}

.form__attribute-line {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
  .form-control {
    flex: 0 0 100%;
  }
  .form-group {
    flex: 0 0 49.5%;
    margin-bottom: 0;
  }
}

.form__attribute-title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  flex: 0 0 100%;
}

.checkbox--inline {
  display: flex;
  align-items: center;
  & > .checkbox {
    margin-right: 0.5rem;
  }
}

.distribution-options__title {
  flex: 0 0 100%;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: $grey-darkest;
}


.AttributeContentControls {
  padding: 1.5rem 1.5rem 0.5rem;
  margin: 0;
  border-top: 1px solid $grey-light;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $white;
}

.radio-container {
  float: left;
  margin-right: 2rem;
}

.sb-radio {
  display: none;
  &:checked {
    & + .radio-label:before {
      background: $grey-medium;
      border-color: $grey-medium;
    }
  }
}

.radio-label {
  position: relative;
  padding-left: 2.2rem;
  &:before {
    content: "";
    width: 1.8rem;
    height: 1.8rem;
    position: absolute;
    left: 0;
    top: 2px;
    border-radius: 100%;
    border: 2px solid $grey-light;
  }
}

.attribute-confirm__title {
  @extend %attribute-modal-table-title;
}

.affected-products {
  position: relative;
  .AutoSizerStuff {
    max-height: 40vh;
  }
}
