@keyframes pulse {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.no-rows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('../../../../styles/imgs/app/loading/product-list-item.svg');
  background-size: contain;
  animation: pulse 1s infinite alternate;
  background-repeat-x: no-repeat;
  // TODO get single row loading indicator to work.
  &--single {
    background: url('../../../../styles/imgs/app/loading/product-list-item.svg');
    background-size: cover;
    // background-position-y: -14px;
    background-repeat-x: no-repeat;
  }
}

.loading {
  position: absolute;
  background-repeat: no-repeat;
  animation: pulse 1s infinite alternate;
}

.loading--product {
  top: 2rem;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  background-image: url('../../../../styles/imgs/app/loading/product-view.svg');
  background-size: cover;
}

.loading--grid {
  background: url('../../../../styles/imgs/app/loading/product-grid-item.svg');
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-size: 25.5%;
}
