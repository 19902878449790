@import '../../styles/core/core';

// product locked notification
.product-locked {
	padding: 0.8rem 1rem 0.8rem 4rem;
	margin-bottom: 2rem;
	color: $blue-dark;
	text-transform: uppercase;
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: 0.08rem;
	line-height: 1.4;
	border: 1px solid $blue-dark;
	border-radius: $radius;
	background: $white;
	box-shadow: 0 1px 6px rgba(0,0,0,0.2);
	position: relative;
}

.product-locked__icon {
	position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 30px;
  background: $blue-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .icon > * {
  	fill: $white;
  }
}

// processing cards
.job-queue-summary {
  display: flex;
}

.job-queue-info {
  padding: 1.5rem 2rem 1rem;
  flex: 0 0 80%;
}

.job-queue-meta {
  padding: 2rem;
  flex: 0 0 20%;
  border-left: 1px solid $grey-light;
}

// inline edit
.inline-edit__unit {
	flex: 0 0 100%;
	display: flex;
	flex-direction: column;
	& + &,
	.form-group + &,
	& + .form-group {
		margin-top: 2rem;
	}
}

.inline-editable {
	cursor: pointer;
}

.inline-edit--editing {
	display: flex;
	align-items: flex-start;
	position: relative;
}

.editable-value {
	display: flex;
	float: left;
	margin: 0 1rem 0 0;
	line-height: 1.4;
}

.icon--edit {
	visibility: hidden;
	fill: $grey-medium;
	.inline-editable:hover & {
		visibility: visible;
		cursor: pointer;
	}
	&:hover {
		fill: $grey-dark;
	}
}

input.input-editing--text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0;
}

.inline-editing {
	width: 3.5rem;
	height: 3.5rem;
	background: $grey-medium;
	border: 1px solid $grey-dark;
	cursor: pointer;
	position: relative;
	transition: background 0.2s ease;
	& + & {
		border-left: 0;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	&:hover {
		background: $grey-dark;
	}
	&--save,
	&--cancel {
		&:before,
		&:after {
			content: '';
			position: absolute;
			background-color: white;
		}
	}
	&--save {
		&:before {
			width: 3px;
			height: 10px;
			transform: rotate(45deg);
			right: 13px;
			top: 11px;
		}
		&:after {
			width: 3px;
			height: 7px;
			transform: rotate(-45deg);
			right: 18px;
			top: 15px;
		}
	}
	&--cancel {
		&:before,
		&:after {
			width: 3px;
			height: 13px;
			top: 11px;
			right: 16px;
		}
		&:before {
			transform: rotate(-45deg);
		}
		&:after {
			transform: rotate(45deg);
		}
	}
}

textarea.input-editing--textarea {
	flex: 0 0 75%;
	border-top-right-radius: 0;
	resize: vertical;
}


// attributes
.product-details__input {
	display: flex;
	flex: 0 0 95%;
	align-items: center;
	margin-right: 1%;
  position: relative;
  .control-label {
  	width: 0; // bugfix for changing width based in control label text content https://stackoverflow.com/questions/7985021/css-flexbox-issue-why-is-the-width-of-my-flexchildren-affected-by-their-content
  }
}

.product-details__input-unit {
  display: flex;
  flex: 1 0 0;
  position: relative;
  input.form-control {
  	flex: 1 0 0;
  	margin-right: 1rem;
  }
  select.form-control {
  	flex: 0 0 20.5%;
  	min-width: 0;
  }
  & + select.form-control {
  	flex: 0 0 15%;
  	min-width: 0;
  }
  textarea.form-control {
  	flex: 1 0 0;
  	resize: vertical;
  }
}

input.formula-active {
  padding-left: 3.5rem;
}

.value-unit,
.formula-equals {
	color: $grey-medium;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  padding: 0 0.75rem;
  background: $grey-lightest;
}

.form-control.appended-unit {
	padding-right: 3.5rem;
}

.value-unit {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid $grey-light;
  right: 1.1rem;
  top: 1px;
}

.formula-equals {
  left: calc(20% + 1px);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 1px solid $grey-light;
}

.attribute-actions {
	display: flex;
	flex: 0 0 5%;
	align-items: center;
	justify-content: space-between;
}
.tooltip-trigger {
	position: relative;
}

.formula-tooltip {
	@include tooltip(calc(50% - 6rem), -4.5rem, calc(50% - 4px), 2.5rem, 12rem);
}

// product summary section
.product-summary {
	@include media-up(md) {
		display: flex;
	}
	.control-label {
		display: block;
		margin-bottom: 1rem;
	}
}

.product-img,
.product-info,
.product-meta {
	padding: $padding;
}

.product-img {
	// display: flex;
  // flex-wrap: wrap;
	align-items: center;
	border-bottom: 1px solid $grey-light;
	color: $grey-medium;
	// position: relative;
	@include media-up(md) {
		flex: 0 0 20%;
		border-bottom: 0;
		border-right: 1px solid $grey-light;
	}
	.helper {
		position: absolute;
		top: 3rem;
		right: 3rem;
	}
}

.tooltip {
	@include tooltip(calc(50% - 7.5rem), 35px, calc(50% - 4px), -4px, 15rem);
}

.product-img__img {
	display: block;
	max-width: 100%;
	max-height: 20rem;
	height: auto;
	margin: 0 auto 2rem;
}

select.product-img__select {
	padding-right: 3rem;
}

.product-info {
	@include media-up(md) {
		flex: 0 0 60%;
	}
}

.product-meta {
	border-top: 1px solid $grey-light;
	@include media-up(md) {
		flex: 0 0 20%;
		border-top: 0;
		border-left: 1px solid $grey-light;
	}
}

.product-info__category,
.product-info__subcategory {
	font-family: $font-main;
	color: $grey-medium;
}

.product-info__category {
	font-size: 2rem;
}

.product-info__subcategory {
	font-size: 1.2rem;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	margin-top: 0.75rem;
}

.meta-item {
	font-size: 1.2rem;
	margin-bottom: 15px;
	&:last-of-type {
		margin-bottom: 0;
	}
}

.meta-item__header {
	font-weight: 500;
	margin: 0;
	text-transform: uppercase;
}

.meta-item__icon {
	position: relative;
	top: 4px;
  margin-right: 8px;
	color: $grey-medium;
}

.meta-item__content {
	margin: 3px 0 0 24px;
  &--flush-left {
    margin-left: 0;
  }
}

.meta-item__tag {
	margin: 5px 2px 0;
	&.btn {
		padding-right: 5px;
		padding-left: 5px;
	}
}

// product content section
.product__content {
	margin-top: 30px;
	.nav-tabs {
		padding: 0 25px;
	}
	.tab-content {
		padding: 25px;
	}
}

.product__controls {
	display: flex;
	align-items: center;
}

// type dropdown
.type-selector {
	display: flex;
	align-items: center;
	margin: 3rem 0;
	select.form-control {
		flex: 0 0 30%;
	}
}

.type-selector__label {
	flex: 0 0 19%;
}

// dropzones
.revit-dropzones {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& > .dropzone {
		width: 48%;
		@include media-up(lg) {
			width: 24%;
		}
	}
}

// shared parameters
.parameter-name {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 1rem;
  }
}

.parameter-tooltip {
  @include tooltip(calc(50% - 10.5rem), -4.5rem, calc(50% - 4px), 2.3rem, 20rem);
}
