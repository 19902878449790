@import '../core/core';

/*

this file sets base styles for react-bootstrap's text input component,
as well as our own search input

*/

%text-input {
  padding: 5px 10px;
  border: 1px solid $grey-light;
  border-radius: 4px;
  font: inherit;
  font-size: 1.4rem;
  &:focus {
    outline: 0;
    border-color: $blue-dark;
  }
}

%disabled {
  background: $grey-lightest;
  color: $grey-medium;
  cursor: not-allowed;
}

.input {
  &--text {
    @extend %text-input;
  }
  &--icon {
    padding-left: 30px;
  }
}

// 14px x 14px icon inside an input field
// TODO replace with svg icon
.input-icon {
  position: absolute;
  top: calc(50% - 8px);
  left: 10px;
}

// react bootstrap Forms inputs
.form-group {
  display: flex;
  flex: 0 0 100%;
  align-items: center;
  margin-bottom: 1rem;
  &.input--block {
    display: block;
    & > .control-label,
    & > .form-control {
      display: block;
      width: 100%;
    }
    & > .control-label {
      margin-bottom: 1rem
    }
  }
}

.control-label {
  flex: 0 0 20%;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  padding-right: 1rem;
}

input.form-control,
select.form-control {
  flex: 0 0 80%;
  @extend %text-input;
    height: 3.5rem;
  &:disabled {
    @extend %disabled;
  }
  &--error {
    border-color: $red;
    background: lighten($red, 35%);
  }
  &--warn {
    border-color: $orange;
  }
  &--success {
    border-color: $green;
  }
}

textarea.form-control {
  @extend %text-input;
  &:disabled {
    @extend %disabled;
  }
}

// validation styling
.form-msg {
  width: 80%;
  margin: 0.25rem 0 0 calc(20% + 1rem);
  font-size: 1.3rem;
  &--error {
    color: $red;
  }
}

// Select styling

select.form-control {
  border: 1px solid $grey-light;
  color: $grey-darkest;
  font-size: 1.4rem;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  user-select: none;
  padding-right: 3rem;
  min-width: 20rem;
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg"><path fill="%23{$grey-medium}" fill-rule="evenodd" d="M 6 4.56204L 10.6668 0.082741C 10.782 -0.0297774 10.9722 -0.0270984 11.0901 0.088099L 11.9099 0.889122C 12.0278 1.00432 12.0304 1.19185 11.9152 1.30437L 6.217 6.7749C 6.15806 6.83384 6.07769 6.86063 6 6.85527C 5.91963 6.85795 5.84194 6.83116 5.783 6.7749L 0.0847496 1.30437C -0.0304477 1.19185 -0.0277687 1.00432 0.0901076 0.889122L 0.909884 0.088099C 1.02776 -0.0270984 1.21797 -0.0297774 1.33317 0.082741L 6 4.56204Z"/></svg>');
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: right 10px center;
  width: 100%;
  max-width: 100%;
  // remove dotted focus outline on FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $grey-medium;
  }
}
