@import '../../styles/core/core';

.job-notifications {
    button.button--default.button--dropdown.dropdown--icon.dropdown-toggle.btn {
      padding: 0 1.5rem 0 0.5rem;
    }
  &.new-notifications {
    button.button--default.button--dropdown.dropdown--icon.dropdown-toggle.btn {
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        right: 8px;
        background-color: $blue-dark;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        border: 2px solid $grey-lightest;
      }
    }
  }

  .dropdown {
    button.button--dropdown {
      border: none;
      background-color: transparent;
    }
  }

  .dropdown-menu {
    width: max-content;
    border: none;
    box-shadow: 0 1px 6px rgba(0,0,0,0.2);

    .notification-job-type-block {
      margin-right: 30px;

      .notification-job-time {
        color: $grey-medium;
        font-size: 1rem;
      }
    }

    .notification-unread {
      position: relative;
      min-width: 10px;
      &:after {
        content: '';
        position: absolute;
        bottom: 7px;
        right: 0;
        background-color: $blue-dark;
        width: 10px;
        height: 10px;
        border-radius: 5px;
      }
    }

    .notification-job-status {
      vertical-align: 50%;
      line-height: 13px;
      margin-right: 20px;
    }

    .dropdown__item {
      border-bottom: 1px solid $grey-light;

      &:hover {
        background: #fff;
      }
    }

    .notification-job-queue-link {
      text-align: center;
    }

    a[role='menuitem'] {
      color: $grey-darkest;
    }
  }
}
