@import '../../../styles/core/core';

.page404 {
  text-align: center;
  width: 45%;
  margin: 4rem auto 0;

  .img404 {
    display: block;
    width: 80%;
    margin: 0 auto;
  }

  .page404-title {
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: -3rem;
  }

  .page404-content {
    margin-top: 2rem;
  }
}
