@import '../../../../styles/core/core';

label.checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;

  input[type="checkbox"] {
    display: none;
  }

  .checkbox__icon {
    fill: $grey-light;
  }
  &.checked .checkbox__icon {
    fill: $grey-medium;
  }

  // hover state for non disabled checkboxes
  &:hover {
    &:not(.disabled) {
      .checkbox__icon {
        fill: $grey-medium;
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
}
