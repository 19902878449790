@import '../../../../styles/core/core';

.parent-nav {
	font-size: 1.4rem;
	transition: color 0.3s ease;
	color: $grey-medium;
	position: relative;
	top: -3px;
	&:hover {
		cursor: pointer;
		color: $blue-dark;
		& > .icon > * {
			fill: $blue-dark;
		}
	}
	& > .icon {
		margin-right: 0.5rem;
		transition: fill 0.3s ease; 
	}
}