@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,700&display=swap');

// colors
$white: white;
$blue-dark: #1385f0; // this is used for radios/checkboxes
$blue: #44bff5; // primary button color alt
$green: #6abf43; // success color
$red: #d95a5a; // error color
$orange: #f59a38; // warning color
$teal: #12ceb8; // primary button color

// greys 1: cooler
$grey-darkest: #343e47;
$grey-dark: #5a6678;
$grey-medium: #77899e;
$grey-light: #c3cdd9;
$grey-lightest: #f0f3f6;

// greys 2: warmer
// $grey-darkest: #2D3E50;
// $grey-dark: #7D7E7F;
// $grey-medium: #ABACA9;
// $grey-light: #D8D8D8;
// $grey-lightest: #F6F5F3;

// fonts
$font-main: 'IBM Plex Sans', 'Helvetica Neue', Helvetica, sans-serif;
$font-heading: 'IBM Plex Sans', 'Helvetica Neue', Helvetica, sans-serif;

// global border-radius
$radius: 4px;

// global spacing
$padding: 20px;

// main sections
$sidebar-top: 75px;
$sidebar-width: 170px;
$sidebar-collapsed-width: 70px;

// z-indices
$z-bottom: 1;
$z-middle: $z-bottom * 100;
$z-top: $z-middle * 10;

// breakpoints
$breakpoints: (
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);
