@import '../core/variables';

/*

This file serves to override react-fine-uploader styles

*/

// the actual dropzone
.react-fine-uploader-gallery-nodrop-container,
.react-fine-uploader-gallery-dropzone {
  border: 2px dashed $grey-medium;
  margin-bottom: 2rem;
  border-radius: $radius;
  transition: border-color 0.3s ease;
  padding: 1rem;
  min-height: 20rem;
}

// disabled dropzoe
.react-fine-uploader-gallery-nodrop-container {
  opacity: 0.4;
  cursor: not-allowed;
}

// active state for dropzone when file is hovered
.react-fine-uploader-gallery-dropzone-active {
  border: 2px solid $blue-dark;
  box-shadow: inset 0 0 14px 0px rgba(85,100,114,0.2);
}

// backdrop
.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 2rem;
  opacity: 1;
  color: $grey-light;
  top: 45%;
}

// backdrop icon
.react-fine-uploader-gallery-dropzone-upload-icon {
  height: 2.5rem;
  fill: $grey-light;
  margin-right: 5px;
  margin-bottom: -4px;
}

// select files button
.react-fine-uploader-gallery-file-input-container {
  color: $grey-medium;
  border: 2px solid $grey-medium;
  background: $white;
  width: 15rem;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: $radius;
  letter-spacing: 0.03rem;
  margin-left: 0;
  &:hover {
    background: $grey-lightest;
  }
}

// icon inside upload button
.react-fine-uploader-gallery-file-input-upload-icon {
  fill: $grey-medium;
  width: 24px;
  height: 20px;
  margin-bottom: -5px;
}

// progress bar container
.react-fine-uploader-gallery-progress-bar-container,
.react-fine-uploader-gallery-total-progress-bar-container {
  border-radius: 1.5rem;
}

.react-fine-uploader-gallery-total-progress-bar-container {
  margin-right: 0;
  margin-top: 1.2rem;
  height: 1.5rem;
  width: calc(100% - 19rem);
}

// progress bar
.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: $green;
  box-shadow: inset 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 1.5rem;
}

// all files
.react-fine-uploader-gallery-files {
  margin: 0 -1rem;
  padding: 0;
}

// individual file
.react-fine-uploader-gallery-file {
  margin: 2rem 1rem 0.01rem 1rem;
  border-radius: $radius;
  box-shadow: none;
  border: 1px solid $grey-light;
  height: auto;
}

// thumbnail
.react-fine-uploader-gallery-thumbnail-container {
  height: auto;
}

.react-fine-uploader-gallery-thumbnail {
  padding: 1rem;
  max-width: 100%;
  transform: none;
  & > svg {
    width: 0;
    height: 0;
  }
}

// icons on thumbnail
.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  opacity: 1;
  top: 15px;
  left: 15px;
}

.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  width: 2rem;
  height: 2rem;
}

.react-fine-uploader-gallery-upload-failed-icon {
  fill: $red;
}

.react-fine-uploader-gallery-upload-success-icon {
  fill: $green;
}

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  width: 3rem;
  height: 3rem;
}

// filename
.react-fine-uploader-gallery-filename {
  white-space: normal;
  word-wrap: break-word;
 }

// footer
.react-fine-uploader-gallery-file-footer {
  padding: 0 1rem 1rem;
}

.react-fine-uploader-gallery-filesize {
  float: none;
}
