@import '../core/core';

// set the table height to cut off just above the footer
.table--AutoSizer {
  height: calc(100vh - 21rem);
  &.table--half-page {
    height: 43rem;
  }
}

.table-header {
  color: $grey-medium;
  &:focus {
    outline: 0;
  }
}

.table-row {
  padding-left: 1rem;
  border-bottom: 1px solid $grey-lightest;
  &:hover {
    background: #F7F7F7;
  }
  &:focus {
    outline: none;
  }
}

.table__visible-items:focus {
  outline: none;
}

.ReactVirtualized__Grid:focus,
.ReactVirtualized__Table__Grid:focus {
  outline: none;
}

.ReactVirtualized__Table__headerRow {
  font-weight: 500 !important;
}
