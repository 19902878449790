@import '../../../styles/core/core';

%button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px 7px;
  border: 2px solid;
  border-radius: $radius;
  font: inherit;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 0.1px;
  line-height: 1;
  outline: 0;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  &:hover {
    cursor: pointer;
  }
}

.button--card {
  color: $grey-medium;
  background: $white;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02rem;
  padding: 6px 8px;
  border: 1px solid $grey-medium;
  float: left;
  margin-right: -1px;
  cursor: pointer;
  &.button-label {
    height: 36px;
    border-width: 2px;
    padding: 4px 6px;
  }
  &:hover {
    background: $grey-lightest;
  }
}
