@import './variables';

// text align
.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
	text-align: left !important;
}

// text colors
.state--neutral {
  color: $grey-medium;
}

.state--success {
  color: $green;
}

.state--warn {
  color: $orange;
}

.state--error {
  color: $red;
}

// fade in out react-bootstrap

%fade {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.in {
    opacity: 1;
  }
}

// spacing utils

$sizes: (
  '0': 0,
  '1': 1rem,
  '2': 2rem,
  '3': 3rem
);

@mixin margin($direction, $value) {
  margin-#{$direction}: $value !important;
}

@mixin padding($direction, $value) {
  padding-#{$direction}: $value !important;
}

@each $name, $size in $sizes {
  @each $abbr, $direction in (t:top, l:left, b:bottom, r:right) {

    .m#{$abbr}-#{$name}{
      @include margin($direction, $size)
    }
    .p#{$abbr}-#{$name}{
      @include padding($direction, $size)
    }
  }
}

// TODO: can we improve mixin to also generate these?
// also add horizontal and vertical classes for all the $sizes

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mh-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mv-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pv-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.m-1 {
	margin: 1rem !important;
}

.p-1 {
	padding: 1rem !important;
}

.mh-1 {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
}

.mv-1 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.ph-1 {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
}

.pv-1 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.display-inline-block {
  display: inline-block;
}

.grey-light {
  color: $grey-medium;
}
