@import '../core/core';

/*

this file sets base modal styles for react-bootstrap's Modal component,
as well as modal variants that we define for modals containing information
regarding warning or danger (.modal--warning, .modal--danger).

*/

.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  overflow: hidden;
  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;      
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 1rem;
  @include media-up(md) {
    width: 60rem;
    margin: 3rem auto;
  }
  @include media-up(lg) {
    width: 80rem;
  }
  @include media-up(xl) {
    width: 90rem;
  }
  .modal.fade & {
    transform: translateY(-25%);
    transition: transform 0.3s ease-out;
  }
  .modal.in & {
    transform: translateY(0);    
  }
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: $radius;
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  background-clip: padding-box;
  outline: 0;
  @include media-up(lg) {
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  }
  .modal--success &,
  .modal--warning &,
  .modal--danger & {
    padding-left: 0.5rem;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 0.5rem;
      height: 100%;
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }
  }
  // TODO: remove .modal--success when alerts are implemented
  .modal--success & {
    &:before {
      background-color: $green;
    }
  }
  .modal--warning & {
    &:before {
      background-color: $orange;
    }
  }
  .modal--danger & {
    &:before {
      background-color: $red;
    }
  }
}

.modal-header {
  border-bottom: 1px solid $grey-light;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 $padding;
  height: 6rem;
}

.header-contents,
.title-container {
  display: flex;
  align-items: center;
  & > svg {
    margin-right: 1rem;
  }
}

.modal-title {
  font-family: $font-main;
  transition: transform 0.3s ease;
  .button + & {
    transform: translateX(0.5rem);
  }
}

.modal-body {
  position: relative;
  padding: $padding;
}

.modal-footer {
  padding: 1.5rem $padding;
  border-top: 1px solid $grey-light;
}

.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  font-size: 4rem;
  line-height: 0;
  position: relative;
  top: -3px;
  color: $grey-darkest;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  transition: opacity 0.3s ease;
  &:hover,
  &:focus {
    color: $grey-darkest;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}

// Visually hide the Close text
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: $grey-darkest;
  &.fade {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  &.in {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
}