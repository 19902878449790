@import '../../../../../styles/core/core';

.footer {
  padding: 10px;
  font-size: 1.2rem;
  color: $grey-medium;
  background: $grey-lightest;
  position: relative;
  top: $sidebar-top;
  z-index: -1;
}
