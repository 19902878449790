$variants: (
  green: $green,
  red: $red,
  orange: $orange
);

@mixin button-variant($color) {
  color: $color;
  border-color: $color;
  background-color: $white;
  &:hover {
    color: $white;
    background-color: $color;
    svg * {
      fill: $white;
    }
  }
  svg * {
    fill: $color;
    transition: fill 0.3s ease;
  }
};

// from https://www.sitepoint.com/managing-responsive-breakpoints-sass/
@mixin media-up($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin tooltip($left, $top, $arrowRight, $arrowTop, $width: auto)  {
  font-size: 1.4rem;
  background: $grey-darkest;
  color: $white;
  text-align: center;
  padding: 6px 8px 5px;
  position: absolute;
  left: $left;
  top: $top;
  border-radius: $radius;
  opacity: 0;
  transition: opacity .3s ease, transform .3s ease;
  pointer-events: none;
  width: $width;
  z-index: $z-top;
  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background: $grey-darkest;
    position: absolute;
    right: $arrowRight;
    top: $arrowTop;
    border-radius: 2px;
    transform: rotate(45deg);
  }
  .tooltip-trigger:hover & {
    opacity: 1;
    transform: translateY(5px);
  }
}